<template>
  <div class="float-container" :class="{ show: showOptions, hidden: !showOptions }">
    <div class="options" :class="{ show: showOptions, hidden: !showOptions }">
      <div class="float-icon" @click="handleShow">
        <Menu v-if="!showOptions"  class="icon" />
        <Close v-else class="close-icon" /> 
      </div>
      <div class="content" :class="{ show: showOptions, hidden: !showOptions }">
        <Icon :tooltip="opened ? 'Fechar menu lateral' : 'Abrir menu lateral'">
          <div class="icon-wrapper back-burger">
            <BackBurger class="icon" :class="{ 'back-burger-action': true, closed: !opened }" @click="handleMenuVisibility" />
          </div>
        </Icon>

        <div class="divider-double">
          <Icon tooltip="Abrir prontuário do paciente em nova aba">
            <div class="icon-wrapper" @click="goToDashboard">
              <PatientDashboard class="icon" />
            </div>
          </Icon>
          <div class="padding-border">
            <Icon>
              <div class="icon-wrapper" id="tooltip-patient2">
                <InfoPaciente class="icon" />
              </div>
              <TooltipInfoPatient target="tooltip-patient2" :patient="attendance?.patient"/>
            </Icon>
          </div>
          <Icon v-can="'FpPac1'" tooltip="Visualizar cadastro do paciente">
            <div class="icon-wrapper" @click="goToProfile">
              <Profile class="icon" />
            </div>
          </Icon>
          <!-- <Icon :tooltip="true ? 'Abrir triagem' : 'Triagem indisponível'">
            <div class="icon-wrapper">
              <Sorting class="icon" />
            </div>
          </Icon> -->
          <!-- <Icon tooltip="Visualizar e comparar exames">
            <div class="icon-wrapper">
              <Exam class="icon" />
            </div>
          </Icon> -->
          <Icon :tooltip="openedHistory ? 'Fechar histórico' : 'Abrir histórico'">
            <div class="icon-wrapper" @click="handleHistoryVisibility">
              <History class="icon" />
            </div>
          </Icon>
          <Icon 
            v-if="attendance && attendance.type === 'ATTENDANCE'"
            :tooltip="hasPreConsultation ? 'Abrir pré-consulta' : 'Pré-consulta indisponível'"
          >
            <div class="icon-wrapper" :class="{ active: hasPreConsultation, inactive: !hasPreConsultation }" @click="openPreConsultation">
              <PreConsultation class="icon" />
            </div>
          </Icon>
        </div>

        <div class="divider">
          <DocumentCounter
            :loading="loadingDocs"
            :docs="docs"
            :exams="exams"
            :getAttendanceFiles="getAttendanceFiles"
          />
          <Icon tooltip="Visualizar exames">
            <div class="icon-wrapper" v-b-modal.exams-modal>
              <Exams class="icon" />
              <b-badge class="badge">{{exams.length}}</b-badge>
            </div>
          </Icon>
        </div>

        <Icon tooltip="Ir ao topo da consulta">
          <div class="icon-wrapper orange" @click="goToTop">
            <ChevronUp class="icon" />
          </div>
        </Icon>
        <Icon tooltip="Ir ao fim da consulta">
          <div class="icon-wrapper orange" @click="goToDown">
            <ChevronDown class="icon" />
          </div>
        </Icon>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import Icon from '@/components/General/Icon'
import Menu from '@/assets/icons/menu.svg'
import Close from '@/assets/icons/close.svg'
import DocumentCounter from '@/components/Attendance/Forms/Components/DocumentCounter'

import BackBurger from '@/assets/icons/back-burger.svg'
import PatientDashboard from '@/assets/icons/patient-dashboard.svg'
import Profile from '@/assets/icons/profile.svg'
// import Sorting from '@/assets/icons/sorting.svg'
import History from '@/assets/icons/history.svg'
// import Exam from '@/assets/icons/exam-icon.svg'
import PreConsultation from '@/assets/icons/pre-consultation.svg'

import Exams from '@/assets/icons/exams.svg';
import ChevronUp from '@/assets/icons/chevron-up.svg'
import ChevronDown from '@/assets/icons/chevron-down.svg'
import InfoPaciente from '@/assets/icons/info-paciente.svg'

export default {
  props: {
    loadingDocs: Boolean,
    docs: Array,
    exams: Array,
    getAttendanceFiles: Function,
  },
  components: {
    Icon, Menu, Close, BackBurger, DocumentCounter,
    PatientDashboard, Profile, History, Exams,
    PreConsultation, ChevronUp, ChevronDown,
    InfoPaciente,
    TooltipInfoPatient: () => import ('../../../General/TooltipInfoPatient.vue')
    // Exam, Sorting
  },
  computed: {
    ...mapGetters('attendance', ['hasPreConsultation']),
    ...mapState({
      opened: state => state.attendance.menu.opened,
      openedHistory: state => state.attendance.history.opened,
      attendance: state => state.attendance.attendance,
    })
  },
  data() {
    return {
      showOptions: false,
    }
  },
  methods: {
    ...mapActions('attendance/menu', ['handleMenuVisibility']),
    ...mapActions('attendance/history', ['handleHistoryVisibility']),
    handleShow() {
      this.showOptions = !this.showOptions
    },
    goToDashboard() {
      window.open(`/pacientes/${this.attendance.patient.id}`, '_blank')
    },
    goToProfile() {
      window.open(`/pacientes/${this.attendance.patient.id}`, '_blank')
    },
    openPreConsultation() {
      this.hasPreConsultation && this.$bvModal.show('preconsulting-modal')
    },
    goToTop() {
      const forms = document.getElementById('forms-container')
      forms.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
    goToDown() {
      const forms = document.getElementById('forms-container')
      forms.scrollTo({
        top: forms.scrollHeight,
        behavior: 'smooth'
      });
    },
  }
}
</script>
<style lang="scss" scoped>
  .show {
    animation-direction: reverse;
    -webkit-transition: width 300ms ease-in-out;
    -moz-transition: width 300ms ease-in-out;
    -o-transition: width 300ms ease-in-out;
    transition: width 300ms ease-in-out;
    width: fit-content;
  }

  .hidden {
    animation-direction: reverse;
    -webkit-transition: width 300ms ease-in-out, visibility 200ms ease-in-out;
    -moz-transition: width 300ms ease-in-out, visibility 200ms ease-in-out;
    -o-transition: width 300ms ease-in-out, visibility 200ms ease-in-out;
    transition: width 300ms ease-in-out, visibility 200ms ease-in-out;
    width: 30px;
  }

  .float-container {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    bottom: 20px;
    right: 20px;
    z-index: 300;

    .options {
      position: relative;
      display: flex;
      justify-content: flex-end;
      .float-icon {
        position: absolute;
        top: 0;
        right: 43px;
        padding: 26px;
        border-radius: 50%;
        background: var(--blue-500);
        z-index: 1;
        box-shadow: 0px 4px 4px rgba(12, 29, 89, 0.1), 0px 16px 22px -8px rgba(12, 29, 89, 0.2);
        cursor: pointer;
        .icon {
          width: 24px;
          height: 24px;
          stroke: white;
        }
        .close-icon {
          width: 24px;
          height: 24px;
          fill: white;
        }
      }
      .content {
        display: inline-flex;
        align-items: center;
        gap: 10px;
        background: white;
        border-radius: 8px;
        padding: 16px 50px 16px 16px;
        margin-right: 35px;
        box-shadow: 0px 4px 4px rgba(12, 29, 89, 0.1), 0px 16px 22px -8px rgba(12, 29, 89, 0.2);
        overflow: hidden;

        &.show {
          width: 100%;
          padding-right: 105px;
          margin-right: 40px;
        }

        &.hidden {
          width: 0%;
          visibility: hidden;
        }

        .icon-wrapper {
          position: relative;
          border-radius: 8px;
          padding: 10px;
          background: var(--blue-100);
          cursor: pointer;
          .icon {
            width: 24px;
            height: 24px;
          }
          &.active {
          background: var(--states-success-soft-green);
          }
          &.inactive {
            .icon {
              stroke: var(--blue-300);
            }
          }

          &.back-burger {
            background: var(--blue-500);
            .icon {
              fill: white;
            }
          }

          &.orange {
            background: var(--light-orange-200);
            .icon {
              stroke-width: 2px;
              stroke: var(--orange);
            }
          }
        }
      }
    }
    .divider-double {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      padding: 0 16px;
      border-right: 1px solid var(--neutral-300);
      border-left: 1px solid var(--neutral-300);
    }

    .divider {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      padding: 0 16px 0 0;
      border-right: 1px solid var(--neutral-300);
    }

    .back-burger-action {
      transition: all 600ms;
      &.closed {
        transform: rotate(180deg);
      }
    }
    .badge {
      position: absolute;
      background: var(--blue-700);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35px;
      height: 20px;
      border-radius: 30px;
      top: -12px;
      left: 5px;
      font-weight: 700;
    }
  }
</style>
