<template>
  <Icon tooltip="Visualizar documentos">
    <div
      class="icon-wrapper"
      v-b-modal.appointment-docs-modal
      :disabled="!docs.length"
    >
      <Documents class="icon" />
      <b-spinner v-if="loading"  class="loading" variant="primary" type="grow" />
      <b-badge v-else :class="{ badge: true, principal }">{{docs.length}}</b-badge>
    </div>
  </Icon>
</template>
<script>
import { mapGetters } from 'vuex';
import Icon from '@/components/General/Icon'
import Documents from '@/assets/icons/documents.svg';

export default {
  components: { Icon, Documents },
  props: {
    principal: Boolean,
    loading: Boolean,
    docs: Array,
    getAttendanceFiles: Function,
  },
  computed: {
    ...mapGetters('pusher', ['channel']),
  },
  async mounted() {
    if (this.principal) {
      this.channel.bind('doc_finished_printable', this.finishedPrintable, this)
      this.channel.bind('doc_finished_all', this.finished, this)
    }
  },
  data() {
    return {
      clinic: JSON.parse(localStorage.getItem('clinic')),
      person: JSON.parse(localStorage.getItem('user')),
    }
  },
  methods: {
    async finishedPrintable(pusherData) {
      if (this.person.id === pusherData.identifier) {
        let { data } = await this.api.getPusherEvent(pusherData.eventId)
        data = JSON.parse(data.payload)
        await this.getAttendanceFiles(data.attendance_id, data.toPrint);
      }
    },
    async finished(pusherData) {
      if (this.person.id === pusherData.identifier) {
        await this.getAttendanceFiles();
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.loading {
  width: 15px;
  height: 15px;
}
.icon-wrapper {
  position: relative;
  border-radius: 8px;
  padding: 10px;
  background: var(--blue-100);
  cursor: pointer;
  .icon {
    width: 24px;
    height: 24px;
  }
}
.badge {
  position: absolute;
  background: var(--blue-700);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 20px;
  border-radius: 30px;
  top: -12px;
  left: 5px;
  font-weight: 700;
  &.principal {
    top: -6px;
    right: -15px;
    left: auto;
  }
}

</style>
