import { render, staticRenderFns } from "./FloatButton.vue?vue&type=template&id=65e3fcc5&scoped=true"
import script from "./FloatButton.vue?vue&type=script&lang=js"
export * from "./FloatButton.vue?vue&type=script&lang=js"
import style0 from "./FloatButton.vue?vue&type=style&index=0&id=65e3fcc5&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65e3fcc5",
  null
  
)

export default component.exports